export const createControl = (config, validation) => {
  return {
      ...config,
      value: '',
      valid: !validation,
      touched: false,
      isBlur: false,
      validation: validation
  }
}

export const validate = (value, validation = null) => {
  if(!validation) {
      return true
  }

  let isValid = true

  if(validation.required) {
      isValid = value.trim() !== '' && isValid
  }

  if(validation.minLength) {
      isValid = value.length >= validation.minLength && isValid
  }

  return isValid
}

export const validateForm = (formControls) => {
  let isFormValid = true

  for (let control in formControls) {
      if (formControls.hasOwnProperty(control)) {
          isFormValid = formControls[control].valid && isFormValid
      }
  }
  return isFormValid
}