import React, { useContext, useEffect, useState } from "react";
import "./Analytics.scss";
import { Auxiliary } from "../../hoc/Auxilary/Auxiliary";
import { Select } from "../../components/UI/Select/Select";
import { AnalyticsDynamic } from "../../components/AnalyticsDynamic/AnalyticsDynamic";
import { LoginHomeContext } from "../../context/loginHome/loginHomeContext";
import { AnalyticsContext } from "../../context/analytics/analyticsContext";
import { Loader } from "../../components/UI/Loader/Loader";
import { Tabs } from "../../components/UI/Tabs/Tabs";

export const Analytics = (props) => {
  const { token, credentials, property, setProperty } =
    useContext(LoginHomeContext);

  const { getFullAnalytics, analyticsData } = useContext(AnalyticsContext);
  const [mount, setMount] = useState(false);

  useEffect(() => {
    async function start() {
      if (token && credentials[property]["ANALYTICSVIEWID"]) {
        if (!analyticsData[property]) {
          try {
            await getFullAnalytics(
              credentials[property]["ANALYTICSVIEWID"],
              token,
              property
            );
          } catch (e) {
            console.log(e);
          }
        }
      } else {
        props.history.push("/");
      }
    }
    if (!mount) {
      setMount(true);
      start();
    }
  }, [
    credentials,
    token,
    property,
    getFullAnalytics,
    props,
    mount,
    analyticsData,
  ]);

  const multiplyProperty = credentials.length > 1;
  let properies = [];

  if (multiplyProperty) {
    properies = credentials.map((credential, index) => {
      return { value: index, text: credential["PROPERTY"] };
    });
  } else {
  }

  const selectChangeHandler = async (value) => {
    setProperty(value);
    try {
      await getFullAnalytics(
        credentials[value]["ANALYTICSVIEWID"],
        token,
        value
      );
    } catch (e) {
      console.log(e);
    }
  };

  const tags = ["Visitors dynamic", "Conversions dynamic"];

  return (
    <div className="Analytics">
      {analyticsData[property] !== undefined ? (
        <Auxiliary>
          {multiplyProperty ? (
            <div className="Select-hidden">
              <Select
                label=""
                onChange={selectChangeHandler}
                options={properies}
                selected={property}
              />
            </div>
          ) : (
            ""
          )}

          <Tabs tags={tags}>
            <AnalyticsDynamic
              analyticsData={analyticsData[property]["visitors"]}
            />
            <AnalyticsDynamic
              analyticsData={analyticsData[property]["conversions"]}
            />
          </Tabs>
        </Auxiliary>
      ) : (
        <Loader />
      )}
    </div>
  );
};
