import React, { useState } from "react";
import "./Select.scss";

export const Select = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [label, setLabel] = useState(props.options[props.selected]["text"]);

  const wrapperClickHandler = () => {
    setIsOpen((prev) => !prev);
  };

  const optionClickHandler = (value) => {
    setLabel(props.options[value]["text"]);
    props.onChange(value);
  };

  return (
    <div className="select-wrapper" onClick={wrapperClickHandler}>
      <div className="label">{props.label}</div>
      <div className={isOpen ? "select open" : "select"}>
        <div className="select__trigger">
          <span>{label}</span>
          <div className="arrow"></div>
        </div>
        <div className="custom_options">
          {props.options.map((option, index) => {
            const cls = [
              "custom_option",
              props.selected === index ? "selected" : "",
            ];
            return (
              <span
                className={cls.join(" ")}
                key={option.value + index}
                data-value={option.value}
                onClick={() => optionClickHandler(option.value)}
              >
                {option.text}
              </span>
            );
          })}
        </div>
      </div>
    </div>

    // <div className={cls}>
    // 	<label htmlFor={htmlLabel}>{props.label}</label>
    // 	<select
    // 				  id={htmlLabel}
    // 				  name={htmlLabel}
    // 				  onChange={(e) => props.onChange(e)}
    // 	> {
    // 		props.options.map((option, index) => {
    // 			const selected = (props.selected === index) ? 'selected' : ''
    // 			return (
    // 				<option key={option.value + index} value={option.value} selected>
    // 					{option.text}
    // 				</option>
    // 			)
    // 		})
    // 	}
    // 	</select>

    // </div>
  );
};
