import React from 'react'
import './Loader.scss'

export const Loader = props => (
  <div className="Loader_wrap">
    <div className="Loader">
      <div /><div />
    </div>
  </div>
)
