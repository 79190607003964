import React, { useContext, useEffect, useState } from "react";
import "./Summary.scss";
import { Select } from "../UI/Select/Select";
import { Auxiliary } from "../../hoc/Auxilary/Auxiliary";
import { LoginHomeContext } from "../../context/loginHome/loginHomeContext";
import { AnalyticsContext } from "../../context/analytics/analyticsContext";
import { SummaryItem } from "../Summary/SummaryItem/SummaryItem";
import { Loader } from "../UI/Loader/Loader";

export const Summary = (props) => {
  const {
    token,
    credentials,
    searchPossition,
    property,
    leadsSummary,
    analyticsRes,
    getCredentials,
    startSummary,
    setSearchPossition,
    setAnalyticsRes,
    setProperty,
  } = useContext(LoginHomeContext);
  const { cacheFullAnalytics } = useContext(AnalyticsContext);
  const [mount, setMount] = useState(false);

  useEffect(() => {
    async function start() {
      getCredentials(token).then((credentials) => {
        startSummary(credentials[property], property);
        return credentials;
      });
    }

    if (!mount) {
      setMount(true);
      start();
    }
  }, [
    property,
    getCredentials,
    startSummary,
    cacheFullAnalytics,
    token,
    props,
    mount,
  ]);

  const multiplyProperty = credentials.length > 1;
  let properies = [];

  const wrappClass = [];
  wrappClass.push("summary__top__wrapper");

  if (multiplyProperty) {
    wrappClass.push("multiple");
    properies = credentials.map((credential, index) => {
      return { value: index, text: credential["PROPERTY"] };
    });
  } else {
  }

  const selectChangeHandler = (value) => {
    setProperty(value);
    setSearchPossition(null);
    setAnalyticsRes(null);
    startSummary(credentials[value], value);
  };

  return (
    <div className="Summary">
      <div className={wrappClass.join(" ")}>
        {multiplyProperty ? (
          <div className="summary__top summary__top-select">
            <Select
              label="Select your property"
              onChange={selectChangeHandler}
              options={properies}
              selected={property}
            />
          </div>
        ) : (
          ""
        )}

        <div className="summary__top summary__top-logo">
          {credentials[property] ? (
            <Auxiliary>
              <img
                className="logo"
                src={credentials[property].LOGOURL}
                alt="Logo"
              />
            </Auxiliary>
          ) : (
            ""
          )}
        </div>
      </div>

      {!(searchPossition && leadsSummary[property] && analyticsRes) ? (
        <Loader />
      ) : (
        <div className="items-wrap">
          <SummaryItem
            title="Google search result"
            data={searchPossition}
            comment={`For key words:\n${credentials[property][
              "SEARCHWORDS"
            ].join(", ")}`}
            isButton={false}
          />

          <SummaryItem
            title="Leads"
            data={leadsSummary[property][0][0]}
            comment={`New ${
              leadsSummary[property][0][0] - leadsSummary[property][1][0]
            }`}
            isButton={false}
          />

          <SummaryItem
            title="Visitors "
            data={analyticsRes}
            comment="For last 7 days"
            isButton={false}
          />
        </div>
      )}
    </div>
  );
};
