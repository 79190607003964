import React, { useState } from "react";
import "./Tabs.scss";


export const Tabs = (props) => {

	const [activeTab, setActiveTab] = useState(0);

  const tabClickHandle = (index) => {
    setActiveTab(index);
  };

	return (
		<div className="Tabs">

			<div className="tags">
				{props.tags.map((tag, index) => {
					return (
						<div className={activeTab === index ? 'active' : ''}
							key={"tag" + index}
							id={index}
							onClick={() => tabClickHandle(index)}
						>{tag}</div>
					)
				})}
			</div>

			<div className="tabs_content">
				{props.children.map((content, index) => {
					return (
						<div className={activeTab === index ? 'active' : ''}
							key={"tab-content" + index}
							id={index}
						>
							{content}
						</div>	
					)
				})}
			</div>

		</div>
	)



}