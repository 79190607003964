export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_CREDENTIALS = "SET_CREDENTIALS";
export const SET_SEARCHPOSSION = "SET_SEARCHPOSSION";
export const SET_LEADSSUMMARY = "SET_LEADSSUMMARY";
export const SET_ANALYTICSRES = "SET_ANALYTICSRES";
export const SET_FORMDATA = "SET_ANALYTICSRES";
export const SET_ANALYTICSDATA = "SET_ANALYTICSDATA";
export const SET_ANALYTICSCACHED = "SET_ANALYTICSCACHED";
export const SET_PROPERTY = "SET_PROPERTY";
