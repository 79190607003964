import React, {useContext, useEffect, useState} from 'react'
import './Home.scss'
import {LoginHomeContext} from '../../context/loginHome/loginHomeContext'
import {Auth} from '../../components/Auth/Auth'
import {Summary} from '../../components/Summary/Summary'



export const Home = () => {
  const {token, autoLogin} = useContext(LoginHomeContext)
  const [mount, setMount] = useState(false)
  
  useEffect(() => {
    if(!mount) {
      setMount(true);
      autoLogin()
    }
  }, [autoLogin, mount])


  return (
      (token !== null) 
      ?  <Summary/>
      : <Auth/>
  )
}