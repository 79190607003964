import React, { useState, useContext } from "react";
import "./Table.scss";
import { Auxiliary } from "../../hoc/Auxilary/Auxiliary";
import { TabTags } from "./TabTags/TabTags";
import { TabContent } from "./TabContent/TabContent";
import { LeadsContext } from "../../context/leads/leadsContext";
import { LoginHomeContext } from "../../context/loginHome/loginHomeContext";

export const Table = () => {
  const { formData } = useContext(LeadsContext);
  const { leadsSummary, property } = useContext(LoginHomeContext);

  const [activeTab, setActiveTab] = useState(0);

  const formList = Object.keys(formData[property]);

  const formDiff = [];

  leadsSummary[property][0][1].map((form, index) => {
    return (formDiff[form["name"]] =
      form["count"] - leadsSummary[property][1][1][index]["count"]);
  });

  const tabClickHandle = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="tab-wrapper">
      <Auxiliary>
        {formList.map((form, index) => {
          if (formData[property][form].length > 0) {
            return (
              <TabTags
                key={"tab" + index}
                id={index}
                form={form}
                isActive={activeTab === index ? true : false}
                onClick={tabClickHandle}
              />
            );
          } else return "";
        })}
        {formList.map((form, index) => {
          if (formData[property][form].length > 0) {
            return (
              <TabContent
                key={"content" + index}
                id={index}
                form={formData[property][form]}
                isActive={activeTab === index ? true : false}
                diff={formDiff[form]}
              />
            );
          } else return "";
        })}
      </Auxiliary>
    </div>
  );
};
