import React, { useState, useContext } from "react";
import "./Auth.scss";
import {
  createControl,
  validate,
  validateForm,
} from "../../form/formFramework";
import { Auxiliary } from "../../hoc/Auxilary/Auxiliary";
import { Input } from "../UI/Input/Input";
import { Button } from "../UI/Button/Button";
import { Loader } from "../UI/Loader/Loader";
import { LoginHomeContext } from "../../context/loginHome/loginHomeContext";

export const Auth = () => {
  const { getToken, getCredentials } = useContext(LoginHomeContext);
  const [isLoading, setLoading] = useState(false);

  const createFormControls = () => {
    return {
      username: createControl(
        {
          label: "Username",
          type: "text",
          placeholder: "Enter your username",
          errorMassage: "Input corrent username",
        },
        {
          required: true,
          minLength: 5,
        }
      ),
      password: createControl(
        {
          label: "Password",
          type: "text",
          placeholder: "Enter your password",
          errorMassage: "Input corrent password",
        },
        {
          required: true,
          minLength: 5,
        }
      ),
    };
  };

  const [formState, setFormState] = useState({
    isFormValid: false,
    formControls: createFormControls(),
  });

  const clearState = () => {
    setFormState((prevState) => ({
      ...prevState,
      formControls: createFormControls(),
      isFormValid: false,
    }));
  };

  const clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach((input) => {
      input.value = "";
    });
  };

  const inputBlueHandler = (controlName) => {
    const formControls = { ...formState.formControls };
    const control = { ...formControls[controlName] };
    control.isBlur = true;
    formControls[controlName] = control;
    setFormState((prevState) => ({
      ...prevState,
      formControls,
    }));
  };

  const inputChangeHandler = (value, controlName) => {
    const formControls = { ...formState.formControls };
    const control = { ...formControls[controlName] };
    control.value = value;
    control.touched = true;
    control.valid = validate(value, control.validation);
    formControls[controlName] = control;
    setFormState((prevState) => ({
      ...prevState,
      formControls,
      isFormValid: validateForm(formControls),
    }));
  };

  const submitHandler = (event) => {
    event.preventDefault();
    event.target.reset();
  };

  const logInHandler = async (event) => {
    setLoading(true);
    const formData = {
      username: formState.formControls.username.value,
      password: formState.formControls.password.value,
    };

		clearState();
		clearForm();
    getToken(formData)
		.then((token) => {
      getCredentials(token);
    })
		// .then(() => {
		// 	setLoading(false);
		// })
  };

  const renderInputs = () => {
    return Object.keys(formState.formControls).map((controlName, index) => {
      const control = formState.formControls[controlName];
      return (
        <Auxiliary key={controlName + index}>
          <Input
            type={control.type}
            label={control.label}
            name={control.label.toLowerCase()}
            placeholder={control.placeholder}
            value={control.value}
            touched={control.touched}
            valid={control.valid}
            isBlur={control.isBlur}
            shouldValidate={!!control.validation}
            errorMassage={control.errorMassage}
            onChange={(event) =>
              inputChangeHandler(event.target.value, controlName)
            }
            onBlur={(event) => inputBlueHandler(controlName)}
          />
        </Auxiliary>
      );
    });
  };

  return (
    <div className="Auth">
      {isLoading ? (
        <Loader />
      ) : (
        <Auxiliary>
          <h1>Log in</h1>
          <form action="/" onSubmit={submitHandler}>
            {renderInputs()}
            <Button
              type="button"
              label="Log in"
              onClick={logInHandler}
              disabled={!formState.isFormValid}
            />
          </form>
        </Auxiliary>
      )}
    </div>
  );
};
