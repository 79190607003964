import React, { useState, useContext} from "react";
import { Link, useLocation} from "react-router-dom";
import Hamburger from "hamburger-react";
import "./Navbar.scss";
import { default as logo } from "../../img/app-mama-logo.svg";
import { Drawer } from "../Navigation/Drawer/Drawer";
import { Auxiliary } from "../../hoc/Auxilary/Auxiliary";
import { LoginHomeContext } from "../../context/loginHome/loginHomeContext";

export const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  const { credentials, property } = useContext(LoginHomeContext);

  const location = useLocation();
  let path = location.pathname;



  const cls = [];
  cls.push("navbar");

  switch (path) {
    case "/":
      break;
    case "/leads":
      cls.push("leads-page");
      break;
    case "/analytics":
      cls.push("analytics-page");
      break;
    default:
      break;
  }

  const fixBody = () => {
    if (document.body.classList.contains("fixed")) {
      document.body.classList.remove("fixed");
    } else {
      document.body.classList.add("fixed");
    }
  };

  const toggleMenuHandle = () => {
    fixBody();
    setOpen((isOpen) => !isOpen);
  };

  const menuCloseHandler = () => {
    setOpen(false);
  };

  return (
    <div className={cls.join(" ")}>
      <div className="container">
        <div className="navbar-wrap">

            <Auxiliary>
              <div className="logo-wrap">
                <Link to="/" className="link">
                  <img src={logo} className="mama-logo" alt="Mama logo" />
                </Link>
								
                <Link to="/" className="link">
									{((credentials.length > 0) && (path !== '/')) ?
                  credentials[property]["PROPERTY"] :
									"Website Report" }
                </Link>
              </div>

              <div className="toggle-btn">
                {credentials.length > 0 ? (
                  <Hamburger toggled={isOpen} toggle={toggleMenuHandle} />
                ) : (
                  ""
                )}
              </div>
            </Auxiliary>

        </div>
      </div>
      <Drawer isOpen={isOpen} onClose={menuCloseHandler} />
    </div>
  );
};
