import React from 'react'
import './Input.scss'

export const Input = props => {
	const htmlLabel  = `${props.type}-${Math.random()}`

	const cls = ["Input"]


  function isInvalid({valid, shouldValidate, touched}) {
    return !valid && shouldValidate && touched
  }

  if(isInvalid(props)&& props.isBlur) {
    cls.push("invalid") 
  } 

		return (



			<div className={cls.join(' ')}>
				<label htmlFor={htmlLabel}>{props.label}</label>
				<input 
					id={htmlLabel}
					type={props.type || 'text'}
					name={props.name}
          placeholder={props.placeholder}
          onBlur={props.onBlur}
					onChange={props.onChange}
					
				/>

				{ 
				(isInvalid(props)&& props.isBlur) ? <span>{props.errorMassage}</span>
				: null
			}

			</div>
	)
}

