export const calculateByDate = (data) => {
  const intervalGenerator = () => {
    let date1 = new Date();
    let date2 = new Date();

    date1.setDate(date1.getDate() - 1);

    const weeks = [];
    const months = [];

    for (let i = 0; i < 6; i++) {
      let interval1 = {};
      let interval2 = {};

      interval1["end"] = Math.floor(date1 / 1000);
      date1.setDate(date1.getDate() - 6);

      interval1["start"] = Math.floor(date1 / 1000);

      weeks[i] = interval1;
      date1.setDate(date1.getDate() - 1);

      interval2["end"] = Math.floor(date2.setDate(0) / 1000);
      interval2["start"] = Math.floor(date2.setDate(1) / 1000);

      months[5 - i] = interval2;
    }

    return { weeks, months };
  };

  const { weeks, months } = intervalGenerator();

  const customData = {
    weekly: [0, 0, 0, 0, 0, 0],
    monthly: [0, 0, 0, 0, 0, 0],
  };

  const labels = {
    weekly: ["", "", "", "", "", ""],
    monthly: ["", "", "", "", "", ""],
  };

  const dateOptions = { month: "2-digit", day: "2-digit" };
  const dateOptions2 = { month: "long" };

  Object.keys(data).forEach((form) => {
    data[form].forEach((submission) => {
      for (let i = 0; i < 6; i++) {
        if (
          weeks[i]["start"] < submission["date"] &&
          submission["date"] < weeks[i]["end"]
        ) {
          customData["weekly"][5 - i]++;
          labels["weekly"][5 - i] = `${new Date(
            weeks[i]["start"] * 1000
          ).toLocaleDateString("en-AU", dateOptions)} - ${new Date(
            weeks[i]["end"] * 1000
          ).toLocaleDateString("en-AU", dateOptions)}`;
        }

        if (
          months[i]["start"] <= submission["date"] &&
          submission["date"] <= months[i]["end"]
        ) {
          customData["monthly"][i]++;
          labels["monthly"][i] = `${new Date(
            months[i]["start"] * 1000
          ).toLocaleDateString("en-AU", dateOptions2)}`;
        }
      }

      // switch (true) {
      // 	case (weeks[0]['start'] < submission['date']) && (submission['date'] < weeks[0]['end']) :
      // 		customData['weekly'][0]++
      // 		break
      // 	case (weeks[1]['start'] <= submission['date']) && (submission['date'] <= weeks[1]['end']) :
      // 		customData['weekly'][1]++
      // 		break
      // 	case (weeks[2]['start'] <= submission['date']) && (submission['date'] <= weeks[2]['end']) :
      // 		customData['weekly'][2]++
      // 		break
      // 	case (weeks[3]['start'] <= submission['date']) && (submission['date'] <= weeks[3]['end']) :
      // 		customData['weekly'][3]++
      // 		break
      // 	case (weeks[4]['start'] <= submission['date']) && (submission['date'] <= weeks[4]['end']) :
      // 		customData['weekly'][4]++
      // 		break
      // 	case (weeks[5]['start'] <= submission['date']) && (submission['date'] <= weeks[5]['end']) :
      // 		customData['weekly'][5]++
      // 		break
      // 	default:
      // }

      // switch (true) {
      // 	case (months[0]['start'] <= submission['date']) && (submission['date'] <= months[0]['end']) :
      // 		customData['monthly'][0]++
      // 		break
      // 	case (months[1]['start'] <= submission['date']) && (submission['date'] <= months[1]['end']) :
      // 		customData['monthly'][1]++
      // 		break
      // 	case (months[2]['start'] <= submission['date']) && (submission['date'] <= months[2]['end']) :
      // 		result['monthly'][2]++
      // 		break
      // 	case (months[3]['start'] <= submission['date']) && (submission['date'] <= months[3]['end']) :
      // 		customData['monthly'][3]++
      // 		break
      // 	case (months[4]['start'] <= submission['date']) && (submission['date'] <= months[4]['end']) :
      // 		customData['monthly'][4]++
      // 		break
      // 	case (months[5]['start'] <= submission['date']) && (submission['date'] <= months[5]['end']) :
      // 		customData['monthly'][5]++
      // 		break
      // 	default:
      // }
    });
  });

  return { customData, labels };
};
