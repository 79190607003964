import React, {useContext} from "react";
import { Link, useLocation } from "react-router-dom";
import { default as home_icon } from "../../img/home.svg";
import {Auxiliary} from '../../hoc/Auxilary/Auxiliary'
import { LoginHomeContext } from "../../context/loginHome/loginHomeContext";
import "./Footer.scss";


export const Footer = () => {
	const {
    token
  } = useContext(LoginHomeContext);


  const location = useLocation();
  let path = location.pathname;

  const cls = [];
  cls.push("footer");

  switch (path) {
    case "/":
      return (
        <div className={cls.join(" ")}>
          <div className="container">
            <div className="footer-wrap">
							{
							token !== null ?
								<Auxiliary>
								<Link to="/leads" className="link">
									Leads
								</Link>
								<Link to="/analytics" className="link">
									Analytics
								</Link>
							</Auxiliary> :
							''
							}
            </div>
          </div>
        </div>
      );
    case "/leads":
      cls.push("leads-page");
      return (
        <div className={cls.join(" ")}>
          <div className="container">
            <div className="footer-wrap">
              <Link to="/" className="link">
                <img src={home_icon} className="home-icon" alt="Mama logo" />
              </Link>
              <Link to="/analytics" className="link">
                Analytics
              </Link>
            </div>
          </div>
        </div>
      );


    case "/analytics":
      cls.push("analytics-page");
      return (
        <div className={cls.join(" ")}>
          <div className="container">
            <div className="footer-wrap">
              <Link to="/" className="link">
                <img src={home_icon} className="home-icon" alt="Mama logo" />
              </Link>
              <Link to="/leads" className="link">
                Leads
              </Link>
            </div>
          </div>
        </div>
      );

    default:
      return (
        <div className={cls.join(" ")}>
          <div className="container">
            <div className="footer-wrap">

						</div>
					</div>
				</div>	
			)		
  }
};
