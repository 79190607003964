import {
  SHOW_ALERT,
  HIDE_ALERT,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  SET_CREDENTIALS,
  SET_SEARCHPOSSION,
  SET_LEADSSUMMARY,
  SET_ANALYTICSRES,
  SET_PROPERTY,
} from "../types";

const handlers = {
  [SHOW_ALERT]: (state, { payload }) => ({ ...state, alertMessage: payload }),
  [HIDE_ALERT]: (state) => ({ ...state, alertMessage: "" }),
  [AUTH_SUCCESS]: (state, { payload }) => ({
    ...state,
    token: payload,
    loading: false,
  }),
  [AUTH_LOGOUT]: (state) => ({
    ...state,
    token: null,
    credentials: [],
    searchPossition: null,
    leadsSummary: [],
    analyticsRes: null,
  }),
  [SET_CREDENTIALS]: (state, { payload }) => ({
    ...state,
    credentials: payload,
  }),
  [SET_SEARCHPOSSION]: (state, { payload }) => ({
    ...state,
    searchPossition: payload,
  }),
  [SET_LEADSSUMMARY]: (state, { payload }) => ({
    ...state,
    leadsSummary: payload,
  }),
  [SET_ANALYTICSRES]: (state, { payload }) => ({
    ...state,
    analyticsRes: payload,
  }),
  [SET_PROPERTY]: (state, { payload }) => ({ ...state, property: payload }),
  DEFAULT: (state) => state,
};

export const loginHomeReducer = (state, action) => {
  const handler = handlers[action.type] || handlers.DEFAULT;
  return handler(state, action);
};
