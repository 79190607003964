import React from "react";
import ReactDOM from "react-dom";
import "./fonts/Gotham-Bold.otf";
import "./fonts/Gotham-Book.otf";
import "./fonts/Gotham-Medium.otf";
import "./index.scss";
import App from "./App";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
