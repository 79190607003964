import React from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import "./App.scss";
import { Layout } from "./hoc/Layout/Layout";
import { Home } from "./containers/Home/Home";
import { Leads } from "./containers/Leads/Leads";
import { Analytics } from "./containers/Analytics/Analytics";
import { Logout } from "./components/Logout/Logout";
import { LoginHomeState } from "./context/loginHome/loginHomeState";
import { LeadsState } from "./context/leads/leadsState";
import { AnalyticsState } from "./context/analytics/analyticsState";
import { Auxiliary } from "./hoc/Auxilary/Auxiliary";

function App() {
  let routes = (
    <Auxiliary>
      <Route path="/analytics" component={Analytics} />
      <Route path="/leads" component={Leads} />
      <Route path="/logout" component={Logout} />
      <Route path="/" exact component={Home} />
      <Redirect to="/" />
    </Auxiliary>
  );

  return (
    <LoginHomeState>
      <LeadsState>
        <AnalyticsState>
          <BrowserRouter basename={"/"}>
            <Layout>{routes}</Layout>
          </BrowserRouter>
        </AnalyticsState>
      </LeadsState>
    </LoginHomeState>
  );
}

export default App;
