import React, { useContext, useState } from "react";
import { LeadsContext } from "../../context/leads/leadsContext";
import { LoginHomeContext } from "../../context/loginHome/loginHomeContext";
import "./LeadsDynamic.scss";
import { CustomChart } from "../Chart/CustomChart/CustomChart";
import { calculateByDate } from "../../services/calculateByDate";
import { ChartSwitcher } from "../Chart/ChartSwitcher/ChartSwitver";
import { Auxiliary } from "../../hoc/Auxilary/Auxiliary";

export const LeadsDynamic = () => {
  const [measure, setMeasure] = useState("weekly");
  const { formData } = useContext(LeadsContext);
  const { property } = useContext(LoginHomeContext);

  const leadsData = calculateByDate(formData[property]);

  const switcherHandler = () => {
    setMeasure((prevState) => {
      if (prevState === "weekly") return "monthly";
      if (prevState === "monthly") return "weekly";
    });
  };

  return (
    <Auxiliary>
      <CustomChart
        customData={leadsData["customData"][measure]}
        labels={leadsData["labels"][measure]}
      />
      <ChartSwitcher onClickHandler={switcherHandler} measure={measure} />
    </Auxiliary>
  );
};
