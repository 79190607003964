import React, { useReducer } from "react";
import axios from "axios";
import { LeadsContext } from "./leadsContext";
import { leadsReducer } from "./leadsReducer";
import { SET_FORMDATA } from "../types";

export const LeadsState = ({ children }) => {
  const initialState = {
    formData: [],
  };

  const [state, dispatch] = useReducer(leadsReducer, initialState);

  const getFormData = async (credentials, leadsSummary, property) => {
    const { WEBSITEURL, WEBSITEAPPPASSWORD } = credentials;
    const res = await axios.post(
      `${WEBSITEURL}/wp-json/leads-report/full-report`,
      leadsSummary,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${WEBSITEAPPPASSWORD}`,
        },
      }
    );

    if (res.data.status === 403) {
    }

    const fromDataLocal = formData;
    fromDataLocal[property] = res.data;
    dispatch({
      type: SET_FORMDATA,
      payload: fromDataLocal,
    });
  };

  const cleanFormData = () => {
    dispatch({
      type: SET_FORMDATA,
      payload: [],
    });
  };

  const { formData } = state;

  return (
    <LeadsContext.Provider
      value={{
        getFormData,
        cleanFormData,
        formData,
      }}
    >
      {children}
    </LeadsContext.Provider>
  );
};
