import { SET_ANALYTICSDATA } from "../types";

const handlers = {
  [SET_ANALYTICSDATA]: (state, { payload }) => ({
    ...state,
    analyticsData: payload,
  }),
  DEFAULT: (state) => state,
};

export const AnalyticsReducer = (state, action) => {
  const handler = handlers[action.type] || handlers.DEFAULT;
  return handler(state, action);
};
